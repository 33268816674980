/* You can add global styles to this file, and also import other style files */
@import '../node_modules/ag-grid-community/styles/ag-grid.css';
@import '../node_modules/@angular/material/prebuilt-themes/indigo-pink.css';
@import '../node_modules/ag-grid-community/styles/ag-theme-balham.css';
@import '../node_modules/bootstrap/dist/css/bootstrap.css' ;
@tailwind base;
@tailwind components;
@tailwind utilities;



html, body { height: 100%; margin :5px;}
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
html{
    overflow-y: scroll;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; 
 }
html::-webkit-scrollbar { 
    width: 0;
    height: 0;
} 

.disable-row {
    color: lightgray !important;
  } 
  .drag-data {
    cursor: pointer; 
    border: 1px dotted;
  }
